<!--suppress HtmlFormInputWithoutLabel -->
<template>
  <v-container class="form-main">
    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>Renda Formal</div>
      </v-col>
    </v-row>
    <v-row class="form-row" no-gutters>
      <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
        Característica da Renda
      </v-col>
      <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
        <select id="cmbCaracteristicaRenda" name="cmbCaracteristicaRenda" style="width:40%"
          v-model="rendaFormal.caracteristica">
          <option :key="item" :value="item" v-for="item in rendas">{{ item }}</option>
        </select>
      </v-col>
    </v-row>
    <div v-if="isCaracteristicaNotSemRenda">
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Tipo de Fonte
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <select id="cmbTipoFonte" name="cmbTipoFonte" style="width:40%" v-model="rendaFormal.tipoFonte">
            <option :key="item" :value="item" v-for="item in fontes">{{ item }}</option>
          </select>
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          {{ labelDocFontePagadora }}
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <input id="docFontePagadora" name="docFontePagadora" type="text" v-mask="maskDocFontePagadora"
            v-model="rendaFormal.cpfCnpj" />
          <a @click="consultar" class="btn-azul" id="btn-consultar">Consultar</a>
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Nome da Fonte Pagadora.
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <input class="text-uppercase" id="fontePagadora" name="fontePagadora" size="35" type="text"
            v-model="rendaFormal.nomeFontePagadora" />
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Ocupação
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <select id="cmbOcupacao" name="cmbOcupacao" v-model="rendaFormal.ocupacao">
            <option :key="item" :value="item" v-for="item in ocupacoesPorTipo.FORMAL">{{ item }}</option>
          </select>
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Data de Admissão
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" :cols="style.colRight">
          <input class="form-input-text" id="dataAdmissao" maxlength="10" name="dataAdmissao" size="8" style="width:40%"
            type="text" v-mask="'##/##/####'" v-model="rendaFormal.dataAdmissao" />
        </v-col>
      </v-row>
      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" :cols="style.colLeft">
          Bruta
        </v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left']" cols="4">
          <input id="bruta" maxlength="14" name="bruta" size="20" type="text" v-model.lazy="rendaFormal.rendaBruta"
            v-currency="currencyOptions" />
        </v-col>

        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="1">
          Líquida
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="4">
          <input id="liquida" name="liquida" type="text" v-model.lazy="rendaFormal.rendaLiquida"
            v-currency="currencyOptions" />
        </v-col>
      </v-row>

      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="4">
          Documento de Comprovante de Renda
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          <select id="cmbComprovanteRenda" name="cmbComprovanteRenda" v-model="rendaFormal.documentoComprovante">
            <option :key="item" :value="item" v-for="item in tiposComprovantesRenda">{{ item }}</option>
          </select>
        </v-col>
      </v-row>

      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="4">
          Data do Comprovante de Renda
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          <input id="dtRefCompr" name="dtRefCompr" type="text" v-mask="'##/####'"
            v-model="rendaFormal.dataComprovante" />
        </v-col>
      </v-row>

      <v-row class="form-row" no-gutters>
        <v-col :align-self="style.alignSelf" :class="style.colLeftClasses" cols="4">
          Imposto de Renda Retido
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          <input id="iImposRendaRetFonte" name="iImposRendaRetFonte" type="text"
            v-model.lazy="rendaFormal.impostoRetido" v-currency="currencyOptions" />
        </v-col>
      </v-row>
    </div>

    <v-row no-gutters>
      <v-col class="text-center my-3">
        <a @click="salvar" class="btn-azul" id="btn-salvar">Salvar</a>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col class="form-header text-center">
        <div>Lista de Rendas Formais</div>
      </v-col>
    </v-row>

    <div :key="index" v-for="(renda, index) in rendasFormais">
      <v-row class="form-row-result form-row-result-first" no-gutters v-if="isCaracteristicaNotSemRendaResult(renda)">
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          <span class="text-capitalize">{{ renda.tipoFonte ? renda.tipoFonte.toLowerCase() : "" }}</span>
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.cpfCnpj }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters v-if="isCaracteristicaNotSemRendaResult(renda)">
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Ocupação
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.ocupacao }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters v-if="isCaracteristicaNotSemRendaResult(renda)">
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Admissão
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.dataAdmissao }}
        </v-col>
      </v-row>

      <v-row :class="['form-row-result', isCaracteristicaNotSemRendaResult(renda) ? '' : 'form-row-result-first']"
        no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Característica da Renda
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.caracteristica }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters v-if="isCaracteristicaNotSemRendaResult(renda)">
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Bruta
        </v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colRightClasses, 'form-col-left']" cols="3">
          {{ renda.rendaBruta }}
        </v-col>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="2">
          Líquida
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="3">
          {{ renda.rendaLiquida }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Comprovante de Renda
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.documentoComprovante }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Data da Comprovação de Renda
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.dataComprovante }}
        </v-col>
      </v-row>

      <v-row class="form-row-result" no-gutters>
        <v-col :align-self="style.alignSelf" :class="[...style.colLeftClasses, 'form-result-label']" cols="4">
          Valor do Imposto de Renda
        </v-col>
        <v-col :align-self="style.alignSelf" :class="style.colRightClasses" cols="8">
          {{ renda.impostoRetido }}
        </v-col>
      </v-row>

      <v-row class="ma-1" no-gutters>
        <v-col class="text-right">
          <a @click="excluir(renda)" id="btn-excluir" class="btn-azul">Excluir</a>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
// noinspection ES6CheckImport
import _ from "lodash";
import { mapCliente, mapClienteFields } from "../../../store/modules/caixaqui-clientes";
import { mapDadosGeraisFields } from "../../../store/modules/dados-gerais";
import { OPTIONS } from "../../../config";
import { createNamespacedHelpers } from "vuex";
import { isNotAfterToday, isValid, isValidDate, moneySmallerOrEqualsThan, requiredMoney } from "../../../validators";
import { required } from "vuelidate/lib/validators";

const { mapMutations: mapClientesMutations } = createNamespacedHelpers("caixaquiClientes");

export default {
  name: "CaixaquiCadastroClientesRendaFormal",

  data: () => ({
    masks: OPTIONS.masks,
    style: {
      colLeft: 3,
      colRight: 9,
      alignSelf: "stretch",
      colLeftClasses: ["form-col-left", "d-flex", "flex-row", "align-center"],
      colRightClasses: ["form-col-right", "d-flex", "flex-row", "align-center"]
    },
    currencyOptions: OPTIONS.currency,
    rendaFormal: {},
    rendaFormalNew: {
      caracteristica: null,
      tipoFonte: null,
      cpfCnpj: null,
      nomeFontePagadora: null,
      ocupacao: null,
      dataAdmissao: null,
      rendaBruta: null,
      rendaLiquida: null,
      documentoComprovante: null,
      dataComprovante: null,
      impostoRetido: null,
      nova: true
    },
    validationMessages: {
      "rendaFormal.caracteristica": { required: "Selecione uma característica de renda." },
      "rendaFormal.tipoFonte": { required: "Selecione o Tipo de Fonte." },
      "rendaFormal.cpfCnpj": { required: "CPF/CNPJ do cliente inválido." },
      "rendaFormal.nomeFontePagadora": {
        required: "Não será possível continuar sem um nome de fonte pagadora. Pressione o botão Consultar."
      },
      "rendaFormal.ocupacao": { required: "Selecione uma ocupação." },
      "rendaFormal.dataAdmissao": {
        isValidDate: "Data de admissão inválida.",
        isNotAfterToday: "Data de admissão não pode ser maior que a data atual."
      },
      "rendaFormal.rendaBruta": { requiredMoney: "Preencha a renda bruta." },
      "rendaFormal.rendaLiquida": {
        requiredMoney: "Preencha a renda líquida.",
        moneySmallerOrEqualsThan: "A renda líquida não pode ser maior que a renda bruta."
      }
    }
  }),
  validations() {
    let val = {
      rendaFormal: {
        caracteristica: { required }
      }
    };

    if (this.rendaFormal.caracteristica !== "SEM RENDA") {
      val.rendaFormal = {
        ...val.rendaFormal,
        tipoFonte: { required },
        cpfCnpj: { required },
        nomeFontePagadora: { required },
        ocupacao: { required },
        dataAdmissao: { isValidDate, isNotAfterToday },
        rendaBruta: { requiredMoney },
        rendaLiquida: { requiredMoney, smallerOrEqualsThan: moneySmallerOrEqualsThan(this.rendaFormal.rendaBruta) }
      };
    }

    return val;
  },
  created() {
    this.rendaFormal = _.cloneDeep(this.rendaFormalNew);
  },
  watch: {
    "rendaFormal.caracteristica": function (val) {
      if (val === "SEM RENDA") {
        this.rendaFormal = _.cloneDeep(this.rendaFormalNew);
        this.rendaFormal.caracteristica = val;
      }
    }
  },
  computed: {
    ...mapClienteFields(["cpf", "rendasFormais"]),
    ...mapCliente(["readOnlyDossie"]),
    ...mapDadosGeraisFields(["rendas", "fontes", "ocupacoesPorTipo", "tiposComprovantesRenda"]),
    isCaracteristicaNotSemRenda() {
      return this.rendaFormal.caracteristica !== "SEM RENDA";
    },
    isCaracteristicaNotSemRendaResult() {
      return renda => renda.caracteristica !== "SEM RENDA";
    },
    labelDocFontePagadora() {
      return this.rendaFormal.tipoFonte === "JURÍDICA" ? "CNPJ" : "CPF";
    },
    maskDocFontePagadora() {
      return this.rendaFormal.tipoFonte === "JURÍDICA" ? this.masks.cnpj : this.masks.cpf;
    }
  },
  methods: {
    handleForwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE AVANCAR PARA A PROXIMA PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    handleBackwardEvent(to) {
      // AQUI PODEMOS VALIDAR QUALQUER COISA ANTES DE VOLTAR PARA A PAGINA
      if (to) {
        this.$router.push(to);
      }
    },
    consultar() {
      if (!_.isNil(this.readOnlyDossie) && !_.isNil(this.readOnlyDossie.clientes)) {
        const clienteDossie = this.readOnlyDossie.clientes.find(r => r.cpf === this.cpf);
        const rendasFormaisDossie = _.get(clienteDossie, "rendasFormais", []);
        this.rendaFormal.nomeFontePagadora = _.get(
          rendasFormaisDossie.find(r => r.cpfCnpj === this.rendaFormal.cpfCnpj),
          "nomeFontePagadora"
        );
      }
    },
    salvar() {
      if (_.size(this.rendasFormais) > 0 && _.find(this.rendasFormais, v => v.caracteristica === "SEM RENDA")) {
        alert(
          'Cliente está cadastrado como "sem renda", ' +
          "atualize a situação na opção Rendas Formais encerrando a renda existente."
        );
        return;
      }

      if (this.rendaFormal.caracteristica === "SEM RENDA" && _.size(this.rendasFormais) > 0) {
        alert("Cliente cadastrado como 'sem renda' possui renda ativa! \nAtualizar o cadastro do cliente!");
        return;
      }

      if (isValid(this.$v, this.validationMessages)) {
        this.addRendaFormal(this.rendaFormal);
        this.rendaFormal = _.cloneDeep(this.rendaFormalNew);
      }
    },
    excluir(renda) {
      this.removeRendaFormal(renda);
    },
    ...mapClientesMutations(["addRendaFormal", "removeRendaFormal"])
  }
};
</script>

<style scoped src="../../../components/caixaqui/caixaqui-style.css">
</style>
<style scoped>
#btn-consultar,
#btn-excluir,
#btn-salvar {
  padding: 3px 12px 3px 12px;
}

#docFontePagadora {
  width: 175px;
  margin-right: 4px;
}

#dataAdmissao {
  width: 170px;
  margin-right: 4px;
}

#bruta {
  width: 175px;
  margin-right: 4px;
}
</style>
