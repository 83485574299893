import _ from "lodash";
import * as moment from "moment";
import numeral from "numeral";
import "numeral/locales";
import { OPTIONS } from "../config";

numeral.locale("pt-br");

export function isValid(vueValidate, validationMessages = {}, alertFirst = true) {
  vueValidate.$touch();

  if (alertFirst && vueValidate.$invalid) {
    const list = _.chain(vueValidate.$flattenParams())
      .map(v => _.join(v.path, "."))
      .uniq()
      .filter(k => _.get(vueValidate, k).$invalid)
      .map(k => {
        const obj = _.get(vueValidate, k);
        const messages = _.get(validationMessages, k);

        let invalidMessages = [];

        _.forEach(messages, (value, key) => {
          if (!obj[key]) {
            invalidMessages.push(value);
          }
        });

        return { key: k, value: obj, message: invalidMessages };
      })
      .value();

    const first = _.first(list);
    alert(_.first(first.message));
  }

  return !vueValidate.$invalid;
}

export function isValidDate(value) {
  return moment(value, OPTIONS.dateFormat, true).isValid();
}

export function isNotAfterToday(date1) {
  const dateMoment = moment(date1, OPTIONS.dateFormat, true);
  return !dateMoment.isAfter(new moment());
}

export function requiredInt(value) {
  return parseInt(value) > 0;
}

export function requiredMoney(value) {
  return value !== "0,00";
}

export function requiredMoneyIf(condition = true) {
  return value => (condition ? requiredMoney(value) : true);
}

export function moneySmallerOrEqualsThan(valueGreater) {
  return value => {
    return numeral(value).value() <= numeral(valueGreater).value();
  };
}

export function validatePhoneSizeIf(condition = true) {
  return value => (condition ? _.inRange(_.size(value), 9, 11) : true);
}

export function validadteDDDSizeIf(condition = true) {
  return value => (condition ? _.size(value) === 2 : true);
}

export function requiredCheckIf(condition) {
  return value => (condition ? !_.isNil(value) : true);
}

export function validatePisIf(condition = true) {
  return value => {
    if (condition) {
      if (_.isNil(value)) {
        return false;
      }

      const multiplicadorBase = "3298765432";
      let total = 0;
      let resto = 0;
      let multiplicando = 0;
      let multiplicador = 0;
      let digito = 99;

      // Retira a mascara
      var numeroPIS = value.replace(/[^\d]+/g, "");

      if (
        numeroPIS.length !== 11 ||
        numeroPIS === "00000000000" ||
        numeroPIS === "11111111111" ||
        numeroPIS === "22222222222" ||
        numeroPIS === "33333333333" ||
        numeroPIS === "44444444444" ||
        numeroPIS === "55555555555" ||
        numeroPIS === "66666666666" ||
        numeroPIS === "77777777777" ||
        numeroPIS === "88888888888" ||
        numeroPIS === "99999999999"
      ) {
        return false;
      } else {
        for (let i = 0; i < 10; i++) {
          multiplicando = parseInt(numeroPIS.substring(i, i + 1));
          multiplicador = parseInt(multiplicadorBase.substring(i, i + 1));
          total += multiplicando * multiplicador;
        }

        resto = 11 - (total % 11);
        resto = resto === 10 || resto === 11 ? 0 : resto;

        digito = parseInt("" + numeroPIS.charAt(10));
        return resto === digito;
      }
    }

    return true;
  };
}
