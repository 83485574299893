var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "form-main" },
    [
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Renda Formal")])
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "form-row", attrs: { "no-gutters": "" } },
        [
          _c(
            "v-col",
            {
              class: _vm.style.colLeftClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colLeft
              }
            },
            [_vm._v("\n      Característica da Renda\n    ")]
          ),
          _c(
            "v-col",
            {
              class: _vm.style.colRightClasses,
              attrs: {
                "align-self": _vm.style.alignSelf,
                cols: _vm.style.colRight
              }
            },
            [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.rendaFormal.caracteristica,
                      expression: "rendaFormal.caracteristica"
                    }
                  ],
                  staticStyle: { width: "40%" },
                  attrs: {
                    id: "cmbCaracteristicaRenda",
                    name: "cmbCaracteristicaRenda"
                  },
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.rendaFormal,
                        "caracteristica",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    }
                  }
                },
                _vm._l(_vm.rendas, function(item) {
                  return _c(
                    "option",
                    { key: item, domProps: { value: item } },
                    [_vm._v(_vm._s(item))]
                  )
                }),
                0
              )
            ]
          )
        ],
        1
      ),
      _vm.isCaracteristicaNotSemRenda
        ? _c(
            "div",
            [
              _c(
                "v-row",
                { staticClass: "form-row", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colLeft
                      }
                    },
                    [_vm._v("\n        Tipo de Fonte\n      ")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colRightClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colRight
                      }
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.rendaFormal.tipoFonte,
                              expression: "rendaFormal.tipoFonte"
                            }
                          ],
                          staticStyle: { width: "40%" },
                          attrs: { id: "cmbTipoFonte", name: "cmbTipoFonte" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.rendaFormal,
                                "tipoFonte",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.fontes, function(item) {
                          return _c(
                            "option",
                            { key: item, domProps: { value: item } },
                            [_vm._v(_vm._s(item))]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "form-row", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colLeft
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.labelDocFontePagadora) +
                          "\n      "
                      )
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colRightClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colRight
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: _vm.maskDocFontePagadora,
                            expression: "maskDocFontePagadora"
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.rendaFormal.cpfCnpj,
                            expression: "rendaFormal.cpfCnpj"
                          }
                        ],
                        attrs: {
                          id: "docFontePagadora",
                          name: "docFontePagadora",
                          type: "text"
                        },
                        domProps: { value: _vm.rendaFormal.cpfCnpj },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.rendaFormal,
                              "cpfCnpj",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c(
                        "a",
                        {
                          staticClass: "btn-azul",
                          attrs: { id: "btn-consultar" },
                          on: { click: _vm.consultar }
                        },
                        [_vm._v("Consultar")]
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "form-row", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colLeft
                      }
                    },
                    [_vm._v("\n        Nome da Fonte Pagadora.\n      ")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colRightClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colRight
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.rendaFormal.nomeFontePagadora,
                            expression: "rendaFormal.nomeFontePagadora"
                          }
                        ],
                        staticClass: "text-uppercase",
                        attrs: {
                          id: "fontePagadora",
                          name: "fontePagadora",
                          size: "35",
                          type: "text"
                        },
                        domProps: { value: _vm.rendaFormal.nomeFontePagadora },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.rendaFormal,
                              "nomeFontePagadora",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "form-row", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colLeft
                      }
                    },
                    [_vm._v("\n        Ocupação\n      ")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colRightClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colRight
                      }
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.rendaFormal.ocupacao,
                              expression: "rendaFormal.ocupacao"
                            }
                          ],
                          attrs: { id: "cmbOcupacao", name: "cmbOcupacao" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.rendaFormal,
                                "ocupacao",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.ocupacoesPorTipo.FORMAL, function(item) {
                          return _c(
                            "option",
                            { key: item, domProps: { value: item } },
                            [_vm._v(_vm._s(item))]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "form-row", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colLeft
                      }
                    },
                    [_vm._v("\n        Data de Admissão\n      ")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colRightClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colRight
                      }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "##/##/####",
                            expression: "'##/##/####'"
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.rendaFormal.dataAdmissao,
                            expression: "rendaFormal.dataAdmissao"
                          }
                        ],
                        staticClass: "form-input-text",
                        staticStyle: { width: "40%" },
                        attrs: {
                          id: "dataAdmissao",
                          maxlength: "10",
                          name: "dataAdmissao",
                          size: "8",
                          type: "text"
                        },
                        domProps: { value: _vm.rendaFormal.dataAdmissao },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.rendaFormal,
                              "dataAdmissao",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "form-row", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: {
                        "align-self": _vm.style.alignSelf,
                        cols: _vm.style.colLeft
                      }
                    },
                    [_vm._v("\n        Bruta\n      ")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: [..._vm.style.colRightClasses, "form-col-left"],
                      attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.lazy",
                            value: _vm.rendaFormal.rendaBruta,
                            expression: "rendaFormal.rendaBruta",
                            modifiers: { lazy: true }
                          },
                          {
                            name: "currency",
                            rawName: "v-currency",
                            value: _vm.currencyOptions,
                            expression: "currencyOptions"
                          }
                        ],
                        attrs: {
                          id: "bruta",
                          maxlength: "14",
                          name: "bruta",
                          size: "20",
                          type: "text"
                        },
                        domProps: { value: _vm.rendaFormal.rendaBruta },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.rendaFormal,
                              "rendaBruta",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: { "align-self": _vm.style.alignSelf, cols: "1" }
                    },
                    [_vm._v("\n        Líquida\n      ")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colRightClasses,
                      attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.lazy",
                            value: _vm.rendaFormal.rendaLiquida,
                            expression: "rendaFormal.rendaLiquida",
                            modifiers: { lazy: true }
                          },
                          {
                            name: "currency",
                            rawName: "v-currency",
                            value: _vm.currencyOptions,
                            expression: "currencyOptions"
                          }
                        ],
                        attrs: { id: "liquida", name: "liquida", type: "text" },
                        domProps: { value: _vm.rendaFormal.rendaLiquida },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.rendaFormal,
                              "rendaLiquida",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "form-row", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                    },
                    [
                      _vm._v(
                        "\n        Documento de Comprovante de Renda\n      "
                      )
                    ]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colRightClasses,
                      attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                    },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.rendaFormal.documentoComprovante,
                              expression: "rendaFormal.documentoComprovante"
                            }
                          ],
                          attrs: {
                            id: "cmbComprovanteRenda",
                            name: "cmbComprovanteRenda"
                          },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.rendaFormal,
                                "documentoComprovante",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.tiposComprovantesRenda, function(item) {
                          return _c(
                            "option",
                            { key: item, domProps: { value: item } },
                            [_vm._v(_vm._s(item))]
                          )
                        }),
                        0
                      )
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "form-row", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                    },
                    [_vm._v("\n        Data do Comprovante de Renda\n      ")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colRightClasses,
                      attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: "##/####",
                            expression: "'##/####'"
                          },
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.rendaFormal.dataComprovante,
                            expression: "rendaFormal.dataComprovante"
                          }
                        ],
                        attrs: {
                          id: "dtRefCompr",
                          name: "dtRefCompr",
                          type: "text"
                        },
                        domProps: { value: _vm.rendaFormal.dataComprovante },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.rendaFormal,
                              "dataComprovante",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "form-row", attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colLeftClasses,
                      attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                    },
                    [_vm._v("\n        Imposto de Renda Retido\n      ")]
                  ),
                  _c(
                    "v-col",
                    {
                      class: _vm.style.colRightClasses,
                      attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.lazy",
                            value: _vm.rendaFormal.impostoRetido,
                            expression: "rendaFormal.impostoRetido",
                            modifiers: { lazy: true }
                          },
                          {
                            name: "currency",
                            rawName: "v-currency",
                            value: _vm.currencyOptions,
                            expression: "currencyOptions"
                          }
                        ],
                        attrs: {
                          id: "iImposRendaRetFonte",
                          name: "iImposRendaRetFonte",
                          type: "text"
                        },
                        domProps: { value: _vm.rendaFormal.impostoRetido },
                        on: {
                          change: function($event) {
                            return _vm.$set(
                              _vm.rendaFormal,
                              "impostoRetido",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "text-center my-3" }, [
            _c(
              "a",
              {
                staticClass: "btn-azul",
                attrs: { id: "btn-salvar" },
                on: { click: _vm.salvar }
              },
              [_vm._v("Salvar")]
            )
          ])
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { staticClass: "form-header text-center" }, [
            _c("div", [_vm._v("Lista de Rendas Formais")])
          ])
        ],
        1
      ),
      _vm._l(_vm.rendasFormais, function(renda, index) {
        return _c(
          "div",
          { key: index },
          [
            _vm.isCaracteristicaNotSemRendaResult(renda)
              ? _c(
                  "v-row",
                  {
                    staticClass: "form-row-result form-row-result-first",
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        class: [
                          ..._vm.style.colLeftClasses,
                          "form-result-label"
                        ],
                        attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                      },
                      [
                        _c("span", { staticClass: "text-capitalize" }, [
                          _vm._v(
                            _vm._s(
                              renda.tipoFonte
                                ? renda.tipoFonte.toLowerCase()
                                : ""
                            )
                          )
                        ])
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        class: _vm.style.colRightClasses,
                        attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(renda.cpfCnpj) + "\n      "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.isCaracteristicaNotSemRendaResult(renda)
              ? _c(
                  "v-row",
                  {
                    staticClass: "form-row-result",
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        class: [
                          ..._vm.style.colLeftClasses,
                          "form-result-label"
                        ],
                        attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                      },
                      [_vm._v("\n        Ocupação\n      ")]
                    ),
                    _c(
                      "v-col",
                      {
                        class: _vm.style.colRightClasses,
                        attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(renda.ocupacao) + "\n      "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm.isCaracteristicaNotSemRendaResult(renda)
              ? _c(
                  "v-row",
                  {
                    staticClass: "form-row-result",
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        class: [
                          ..._vm.style.colLeftClasses,
                          "form-result-label"
                        ],
                        attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                      },
                      [_vm._v("\n        Admissão\n      ")]
                    ),
                    _c(
                      "v-col",
                      {
                        class: _vm.style.colRightClasses,
                        attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(renda.dataAdmissao) + "\n      "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-row",
              {
                class: [
                  "form-row-result",
                  _vm.isCaracteristicaNotSemRendaResult(renda)
                    ? ""
                    : "form-row-result-first"
                ],
                attrs: { "no-gutters": "" }
              },
              [
                _c(
                  "v-col",
                  {
                    class: [..._vm.style.colLeftClasses, "form-result-label"],
                    attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                  },
                  [_vm._v("\n        Característica da Renda\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: _vm.style.colRightClasses,
                    attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(renda.caracteristica) + "\n      "
                    )
                  ]
                )
              ],
              1
            ),
            _vm.isCaracteristicaNotSemRendaResult(renda)
              ? _c(
                  "v-row",
                  {
                    staticClass: "form-row-result",
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      {
                        class: [
                          ..._vm.style.colLeftClasses,
                          "form-result-label"
                        ],
                        attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                      },
                      [_vm._v("\n        Bruta\n      ")]
                    ),
                    _c(
                      "v-col",
                      {
                        class: [..._vm.style.colRightClasses, "form-col-left"],
                        attrs: { "align-self": _vm.style.alignSelf, cols: "3" }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(renda.rendaBruta) + "\n      "
                        )
                      ]
                    ),
                    _c(
                      "v-col",
                      {
                        class: [
                          ..._vm.style.colLeftClasses,
                          "form-result-label"
                        ],
                        attrs: { "align-self": _vm.style.alignSelf, cols: "2" }
                      },
                      [_vm._v("\n        Líquida\n      ")]
                    ),
                    _c(
                      "v-col",
                      {
                        class: _vm.style.colRightClasses,
                        attrs: { "align-self": _vm.style.alignSelf, cols: "3" }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(renda.rendaLiquida) + "\n      "
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-row",
              { staticClass: "form-row-result", attrs: { "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  {
                    class: [..._vm.style.colLeftClasses, "form-result-label"],
                    attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                  },
                  [_vm._v("\n        Comprovante de Renda\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: _vm.style.colRightClasses,
                    attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(renda.documentoComprovante) +
                        "\n      "
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "form-row-result", attrs: { "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  {
                    class: [..._vm.style.colLeftClasses, "form-result-label"],
                    attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                  },
                  [_vm._v("\n        Data da Comprovação de Renda\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: _vm.style.colRightClasses,
                    attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(renda.dataComprovante) + "\n      "
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "form-row-result", attrs: { "no-gutters": "" } },
              [
                _c(
                  "v-col",
                  {
                    class: [..._vm.style.colLeftClasses, "form-result-label"],
                    attrs: { "align-self": _vm.style.alignSelf, cols: "4" }
                  },
                  [_vm._v("\n        Valor do Imposto de Renda\n      ")]
                ),
                _c(
                  "v-col",
                  {
                    class: _vm.style.colRightClasses,
                    attrs: { "align-self": _vm.style.alignSelf, cols: "8" }
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(renda.impostoRetido) + "\n      "
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "v-row",
              { staticClass: "ma-1", attrs: { "no-gutters": "" } },
              [
                _c("v-col", { staticClass: "text-right" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn-azul",
                      attrs: { id: "btn-excluir" },
                      on: {
                        click: function($event) {
                          return _vm.excluir(renda)
                        }
                      }
                    },
                    [_vm._v("Excluir")]
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }